

export default function Navbar() {
    return (
        <nav className=" h-20 border-b-2 border-black bg-gray-800 p-3 text-white">
            <div className="grid grid-cols-3 md:px-4 place-content-around">
                <a href="/#Startpage" className="text-6xl font-mono">
                    BmTSP
                </a>
                <div className="col-span-2 text-right px-5 py-2 grid grid-cols-1 place-content-around">
                    <div>
                        <a href="https://github.com/MauritzOrlinski/BmTSP" className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black dark:border-white dark:text-white text-black rounded-lg font-bold transform hover:translate-y-2 transition duration-400">Zum Github</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}