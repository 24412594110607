import React from 'react';
import Navbar from "./Components/Navbar";
import './App.css';
import { MathJaxContext } from 'better-react-mathjax';
import {MathJax} from "better-react-mathjax";

function App() {
  return (
      <MathJaxContext>

        <Navbar />
        <div className=" ">
            <section id="Startpage" className="h-screen text-white grid place-content-around text-center bg-black relative snap-center">
            <div >
                <h1 className="text-4xl md:text-6xl font-bold m-8">
                Batteriebasierte Streckenplanung für UAVs im mTSP durch Genetische Algorithmen
            </h1>
                <div className="m-5 font-sans">
                    <p>
                        Ein Jugend Forscht Projekt von Mauritz Orlinski.
                    </p>
                </div>
            </div>
            </section>
            <section id="Navigation" className="h-[70vh] grid place-content-around bg-neutral-400 text-center relative snap-center">
                <div >
                    <h1 className="text-4xl m-3">Hier kannst du entscheiden wo du hin willst:</h1>
                    <div className="grid grid-cols-2 md:grid-cols-3 m-3 font-mono underline">
                        <a href="/#TheProjekt" className="m-3 ">Was ist dieses Projekt?</a>
                        <a href="/#Problem" className="m-3">Problemstellung</a>
                        <a href="/#PossibleApplications" className="m-3">Mögliche Anwendungen</a>
                        <a href="/#Solution" className="m-3">Lösungsansatz</a>
                        <a href="/#qa" className="m-3">Q&A - Fragen und Antworten</a>
                        <a href="/#repo" className="m-3">Zum Github Repo</a>
                    </div>
                    <p className="m-5 text-xl font-bold">
                        Alternativ einfach Scrollen
                    </p>
                </div>
            </section>
            <section id="TheProjekt" className="h-screen grid place-content-around text-center relative snap-center">
                <div className="text-left m-10 grid md:grid-cols-2">
                    <h1 className="text-4xl font-bold m-3 w-1/2">
                        Was ist dieses Projekt?
                    </h1>
                    <p className="text-xl font-medium m-2 ">
                        Das hier ist die Website zu dem Jugend Forscht Projekt: "Batteriebasierte Streckenplanung für UAVs im mTSP durch Genetische Algorithmen"
                        von Mauritz Orlinski. Dieses Projekt wurde im Rahmen von <a className="underline" href="https://www.jugend-forscht.de/">Jugend Forscht</a> entwickelt und umgesetzt.
                        Es fokussiert sich auf ein relevantes Routing Problem in der Robotik: der Streckenplanung mit Batterie Beschränkung.
                    </p>
                </div>
            </section>
            <section id="Problem" className="h-screen grid place-content-around bg-black text-white text-center relative snap-center">
                <div className="text-left m-10 grid md:grid-cols-2">
                    <h1 className="text-4xl font-bold m-3 w-1/2">
                        Was ist die Problemstellung?
                    </h1>
                    <p className="text-xl font-medium m-2 ">
                        Angenommen wir haben n-Punkte, m-Ladestationen und k-UAVs (UAV steht für Unmanned Aerial Vehicle).
                        Die UAVs sind durch eine Batterie in der Reichweite beschränkt, diese kann durch das besuchen einer
                        Ladestation wieder voll geladen werden (in der Praxis eventuell eher getauscht). Das Ziel ist alle n
                        Punkte so ab zufliegen, dass jeder Punkt von genau einem UAV besucht wird. Wie kann man den einen
                        geeigneten Pfad zwischen all diesen Punkten finden, sodass die Batterie zu keinem Zeitpunkt unter
                        Null liegt, bzw. der Unterschleif Minimal ist?
                    </p>
                </div>
            </section>
            <section id="PossibleApplications" className="h-screen grid place-content-around text-center relative snap-center">
                <div className="text-left m-10 grid md:grid-cols-2">
                    <h1 className="text-4xl font-bold m-3 w-1/2">
                        Was wären Mögliche Anwendungsfelder von diesem Projekt?
                    </h1>
                    <p className="text-xl font-medium m-2 ">
                        Da in da das mTSP (multi-Travelling Salesman Problem) die Basis vieler Routing Probleme in der Robotik ist, sind die Anwendungsmöglichkeiten sehr breit.
                        Gebiete wo der artige Probleme auftreten findet man Beispielsweise in der Logistik, der Landwirtschaft,
                        der Vermissten-Suche, dem Katastrophenmanagement, der wissenschaftliche Forschung, der Luftüberwachung und der Inspektion.
                    </p>
                </div>
            </section>
            <section id="Solution" className="h-screen grid place-content-around text-center bg-black text-white relative snap-center">
                <div className="text-left m-10 grid md:grid-cols-2">
                    <h1 className="text-4xl font-bold m-3 w-1/2">
                        Welchen Lösungsansatz verfolge ich?
                    </h1>
                    <p className="text-xl font-medium m-2 ">
                        Aufgrund der Hohen Komplexität des Problems ist die Verwendung von Präzisen Verfahren sehr Langsam und ineffizient.
                        Daher nutze ich eine sogenannte Heuristik. Das von mir verwendete Verfahren ist ein Zwei-Stufen Ansatz:
                        <ul className="m-2">
                            <li>
                                <b>1. Genetischer Algorithmus:</b> Zu erst wird versucht eine Lösung für das mTSP mit den
                                Punkten zu finden, so dass alle Punkte je einmal besucht werden und die gebrauchte Zeit möglichst minimal ist.
                                Dazu nutze ich einen Genetischen Algorithmus.
                            </li>
                            <li>
                                <b>2. Batteriebasierte Streckenplanung:</b> In einem zweiten Schritt werden dann in die Route
                                Ladestopps eingefügt, so dass die Batterie zu keinem Zeitpunkt unter Null fällt. Dazu verwende ich aktuell
                                noch ein simples Brute-Force Verfahren, bei dem ich nach einer möglichen Lösung suche.
                                Dieser Schritt ist aktuell noch sehr ineffizient und langsam. Weitere Forschung ist hier geplant.
                            </li>
                        </ul>
                        Das Programm läuft relativ schnell für Probleme mit vielen UAVs, skaliert aber nicht gut mit der
                        Anzahl der Punkten und Ladestationen.
                    </p>
                </div>
            </section>
            <section id="qa" className="h-full grid place-content-around text-center relative snap-center">
                <div className="text-left m-10">
                    <h1 className="text-4xl font-bold m-3 w-1/2">
                        Q&A - Fragen und Antworten
                    </h1>
                    <div className="text-left grid md:grid-cols-3 gap-2">
                        <h1 className="text-2xl font-bold m-3 w-1/2">
                            Was ist ein Genetischer Algorithmus?
                        </h1>
                        <p className="text-xl font-medium m-2 col-span-2">
                            Ein Genetischer Algorithmus ist ein Optimierungsverfahren, das von der Evolutionstheorie inspiriert ist.
                            Dabei wird eine Population von Lösungen erstellt und dann nach dem Prinzip der natürlichen Selektion
                            und der Vererbung weiterentwickelt. Dabei werden die besten Lösungen bevorzugt. Dieser Prozess wird
                            häufig in der Industrie und Forschung verwendet, um komplizierte Optimierungsprobleme zu lösen.
                            Der Vorteil ist, dass der Algorithmus Problem unabhängig ist und nicht von der Anzahl der Dimensionen
                            des Problems abhängt. Da es sich hier aber um eine Heuristik handelt, kann nicht immer eine optimale
                            Lösung garantiert werden.
                        </p>
                        <h1 className="text-2xl font-bold m-3 w-1/2">
                            Was ist die SAT-Komponente?
                        </h1>
                        <p className="text-xl font-medium m-2 col-span-2">
                            Die SAT-Komponente ist das im Programm liegende Erfüllbarkeitsproblem. Da zu Beginn nicht klar ist,
                            ob es für das Problem überhaupt eine Lösung gibt, müsste zu beginn geprüft werden, ob das Problem
                            überhaupt lösbar ist. Diese Prüfung ist NP-Vollständig und daher sehr aufwendig. Daher wird diese
                            Prüfung in diesem Programm nicht durchgeführt. stattdessen wird davon ausgegangen, dass das Problem
                            lösbar ist und es wird versucht eine Lösung zu finden. Sollte keine Lösung gefunden werden, wird
                            der Batterie Unterschleif versucht zu minimieren.
                        </p>
                        <h1 className="text-2xl font-bold m-3 w-1/2">
                            Kann ich das Programm auch nutzen? Kann ich mitmachen?
                        </h1>
                        <p className="text-xl font-medium m-2 col-span-2">
                            Dieses Projekt ist ein Open Source Projekt. Das bedeutet, jeder kann mitmachen und das Projekt verbessern.
                            Das Projekt ist auf GitHub verfügbar. Ich freue mich über jeden, der mitmachen will. Mehr Informationen
                            dazu findest du <a className="underline" href="/#repo">hier</a>.
                        </p>
                        <h1 className="text-2xl font-bold m-3 w-1/2">
                            Wer bin ich?
                        </h1>
                        <p className="text-xl font-medium m-2 col-span-2">
                            Ich bin Mauritz Orlinski, ein Informatik Student an der Technischen Universität München. Ich habe
                            dieses Projekt im Rahmen von Jugend Forscht entwickelt und umgesetzt. Mehr über mich findest du auf
                            meiner <a className="underline" href="https://mauritzorlinski.com">Website</a>.
                        </p>
                    </div>
                </div>
            </section>

            <section id="repo" className="h-screen grid place-content-around text-center bg-black text-white relative snap-center">
                <div className="text-left m-10 grid md:grid-cols-2">
                    <h1 className="text-4xl font-bold m-3 w-1/2">
                        Das Programm ist auf GitHub verfügbar:
                    </h1>
                    <p className="text-xl font-medium m-2 ">
                        Das Programm ist auf GitHub verfügbar, dort kann jeder das Programm herunterladen und nutzen.
                        Mehr Informationen findet man in der README.
                        <div className="m-10">
                            <a href="https://github.com/MauritzOrlinski/BmTSP" className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black dark:border-white dark:text-white text-black rounded-lg font-bold transform hover:translate-y-2 transition duration-400">Take me to GitHub</a>
                        </div>
                    </p>

                </div>

            </section>
        </div>
      </MathJaxContext>

  );
}

export default App;
